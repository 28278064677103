<template>
<div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">
    <show-modal v-if="showQuestion" :closeShowQuestion="closeShowQuestion" :selectedReport="selectedReport"></show-modal>
    <ReportModal v-if="deleteReport" :closeDeleteReport="closeDeleteReport" :selectedReport="selectedReport" :closeDeleteReportWithdelete="closeDeleteReportWithdelete" />
    <div class="flex h-full w-full" :class="isLoading ? 'hidden' : 'block'">
        <div class="flex sm:flex-row w-full flex-col h-full">
            <div class="flex flex-col w-full sm:mt-0  ">
                <div class="inline-flex justify-between">
                    <div class="mb-2">
                      <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des signalement</p>
                    </div>
                    <div></div>
                </div>
                <div class="border rounded-lg py-3 h-full">
                  <div class="mb-4 px-4 w-full flex sm:flex-row flex-col justify-center">
                    <div class="sm:w-1/5 w-full m-1">
                      <el-select @change="GetModuleOfYear" v-model="year" placeholder="Annee"
                        class="w-full">
                        <el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="sm:w-1/5 w-full m-1">
                      <el-select @change="GetCourseOfModule" v-model="mod"
                        placeholder="Modules" class="w-full">
                        <el-option v-for="item in modules" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="sm:w-1/5 w-full m-1">
                      <el-select @change="onChangeCourse" v-model="course" placeholder="Cours" class="w-full">
                        <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                    </div>
                  <div class="mb-4 px-4 w-full inline-flex justify-center">
                    <el-select @change="searchReport" v-model="reportType" multiple placeholder="Types de signalement" class="w-1/2 ">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div :class="isLOadingQuestions ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-32 w-full">
                    <!--<div class="fulfilling-square-spinner">
                      <div class="spinner-inner"></div>
                    </div>-->
                    <div class="loading">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>

                  <div :class="isLOadingQuestions ? 'hidden' : 'block'">
                    <div v-if="reports.length == 0">
                      <div class="flex justify-center items-center h-full p-5 sm:mt-12">
                        <el-result
                          icon="info"
                          title="Aucun signalement"
                          subTitle="Vous n'avez aucun signalement"
                        >
                        </el-result>
                      </div>
                    </div>

                    <div v-else>
                      <div class="mx-4 mb-3">
                        <el-scrollbar height="280px" class="w-full rounded-lg bg-white">
                          <div v-for="report in reports" :key="report.id" class="flex justify-between items-center border rounded-md p-1 my-1 mx-6 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                              <div>
                                  <el-popover effect="light" trigger="hover" placement="right" :width="500">
                                    <template #default>
                                      <p class="font-bold">cours : {{ report.course_title }}</p>
                                      <div class="flex">
                                        <p class="font-bold">module : {{ report.module_title }},</p>
                                        <p class="font-bold mx-2">{{ report.module_year }} année</p>
                                      </div>
                                      <p>{{ report.comment }}</p>
                                    </template>
                                    <template #reference>
                                      <div class="name-wrapper">
                                        <el-tag>
                                          <span>{{ report.comment.split(" ")[0] }} {{ report.comment.split(" ")[1] }} {{ report.comment.split(" ")[2] }} </span>
                                          <span v-if="report.comment.split(' ')[2]"> ...</span>
                                        </el-tag>
                                      </div>
                                    </template>
                                  </el-popover>
                              </div>

                              <div class="flex sm:flex-row flex-col justify-center items-center">
                                <div class="text-center">
                                  <span v-if="report.type == 'La réponse de cette question est erronée'" class="text-sm sm:mx-10"> {{ report.type }} </span>
                                  <span v-if="report.type == 'L\'explication de cette question est inconvenable'" class="text-sm sm:mx-10"> {{ report.type }} </span>
                                  <span v-if="report.type == 'La question ne correspond pas à ce cours'" class="text-sm sm:mx-10"> {{ report.type }} </span>
                                  <span v-if="report.type == 'Il y a une erreur de grammaire'" class="text-sm sm:mx-10"> {{ report.type }} </span>
                                  <span v-if="report.type == 'Aucune de ces réponses'" class="text-sm sm:mx-10"> Autre </span>
                                </div>
                                <div class="flex item-center justify-center">
                                    <div @click="(showQuestion = true) && (selectedReport = report.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </div>
                                    <div @click="(deleteReport = true) && (selectedReport = report.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                      <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </el-scrollbar>
                      </div>

                      <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center ">
                        <li>
                          <button :disabled="prevp === null" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="prevPage"
                          >
                            <span class="sr-only">Previous</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                        <li v-for="page in lastp" :key="page">
                          <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class=" page === currentp ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"

                            @click.prevent="refine(page)"
                          >
                            {{ page }}
                          </button>
                        </li>

                        <li>
                          <button class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="nextPage"
                          >
                            <span class="sr-only">Next</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>

            </div>
        </div>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>

</template>

<script>

import ShowModal from './ReportModal/ShowModal.vue'
import ReportModal from './ReportModal/DeleteModal.vue'
import { ElMessage } from 'element-plus'
import axiosIns from '../../plugins/axios'

export default {
    components: {
      ShowModal,
      ReportModal
    },

    data () {
        return {
            currentRoute: window.location.pathname,
            showQuestion: false,
            deleteReport:false,
            selectedReport: null,

            isLoading : false,
            isLOadingQuestions : false,
            questions :[],
            reports : [],
            reportType: [],
            selectedModuleId : null,
            selectedQuestion:{
              id: null,
              type: null
            },
            options:[
                        {
                          value: 'La réponse de cette question est erronée',
                          label: 'La réponse de cette question est erronée',
                        },
                        {
                          value: "L'explication de cette question est inconvenable",
                          label: "L'explication de cette question est inconvenable",
                        },
                        {
                          value: 'La question ne correspond pas à ce cours',
                          label: 'La question ne correspond pas à ce cours',
                        },
                        {
                          value: 'Il y a une erreur de grammaire',
                          label: "Il y a une erreur de grammaire",
                        },
                        {
                          value: 'Aucune de ces réponses',
                          label: "Aucune de ces réponses",
                        },
                    ],
          year: null,
          years: [
            {
              value: '1',
              label: '1 Année',
            },
            {
              value: '2',
              label: '2 Année',
            },
            {
              value: '3',
              label: '3 Année',
            },
            {
              value: '4',
              label: '4 Année',
            },
            {
              value: '5',
              label: '5 Année',
            },
            {
              value: '6',
              label: '6 Année',
            },
            {
              value: 'noyear',
              label: 'Aucune',
            },
          ],
            
            modules : null,
            mod: null,
            courses: null,
            course: null,

            nextp : null,
            prevp : null,
            lastp : null,
            currentp : null,

            getTypeForPag : ["La réponse de cette question est erronée","L'explication de cette question est inconvenable","La question ne correspond pas à ce cours","Il y a une erreur de grammaire","Aucune de ces réponses"]
        }
    },
    props:{
      linkPath:Function,
    },

    mounted () {
        this.linkPath(this.currentRoute)
        this.isLoading = true;

        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice,element]
        });
       

        if(choice.length == 0){
          choice  =  ["La réponse de cette question est erronée","L'explication de cette question est inconvenable","La question ne correspond pas à ce cours","Il y a une erreur de grammaire","Aucune de ces réponses"];
        }
        
        axiosIns
          .get("/getfiltredreports/" + JSON.stringify(choice) +"/"+this.year+"/"+this.mod+"/"+this.course)
        .then(({ data }) => {
            this.reports = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
    },

    methods: {
      GetModuleOfYear(){
        this.isLoading = true;
        this.mod = null;
        this.course = null;
        this.modules = null;
        this.courses = null;
        if (this.year === 'noyear'){
          this.year = null;
          this.isLoading = false;
        }else{
          axiosIns
            .get("/moduleofyear/" + this.year)
            .then(({ data }) => {
              this.modules = data.modules;
              // this.isLoading = false;
            })
            .catch(() => {
              // this.isLoading = false;
            });
        }
        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice, element]
        });


        if (choice.length == 0) {
          choice = ["La réponse de cette question est erronée", "L'explication de cette question est inconvenable", "La question ne correspond pas à ce cours", "Il y a une erreur de grammaire", "Aucune de ces réponses"];
        }
        axiosIns.get("/getfiltredreports/" + JSON.stringify(choice) + "/" + this.year + "/" + this.mod + "/" + this.course)
          .then(({ data }) => {
            this.reports = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      },
      GetCourseOfModule (){
        this.courses = null;
        this.course = null;
        this.isLoading = true;
        axiosIns.get("/courseofmodule/" + this.mod)
          .then(({ data }) => {
            this.courses = data.courses;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice, element]
        });


        if (choice.length == 0) {
          choice = ["La réponse de cette question est erronée", "L'explication de cette question est inconvenable", "La question ne correspond pas à ce cours", "Il y a une erreur de grammaire", "Aucune de ces réponses"];
        }
        axiosIns.get("/getfiltredreports/" + JSON.stringify(choice) + "/" + this.year + "/" + this.mod + "/" + this.course)
          .then(({ data }) => {
            this.reports = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      },
      onChangeCourse(){
        this.isLoading = true;
        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice, element]
        });


        if (choice.length == 0) {
          choice = ["La réponse de cette question est erronée", "L'explication de cette question est inconvenable", "La question ne correspond pas à ce cours", "Il y a une erreur de grammaire", "Aucune de ces réponses"];
        }
        axiosIns.get("/getfiltredreports/" + JSON.stringify(choice) + "/" + this.year + "/" + this.mod + "/" + this.course)
          .then(({ data }) => {
            this.reports = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLoading = false;
           
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      },

      closeShowQuestion(){
        this.showQuestion = false;
        this.selectedReport = null
      },
      closeDeleteReport(){
        this.deleteReport = false;
        this.selectedReport = null
      },
      closeDeleteReportWithdelete(){
        this.reports = this.reports.filter((element) => element.id !== this.selectedReport);
        this.deleteReport = false
      },

      searchReport(){
        this.prevp = null;
        this.nextp = null;
        this.currentp = null;
        this.lastp = null;

        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice,element]
        });
       

        if(choice.length == 0){
          choice  =  ["La réponse de cette question est erronée","L'explication de cette question est inconvenable","La question ne correspond pas à ce cours","Il y a une erreur de grammaire","Aucune de ces réponses"];
        }

        this.getTypeForPag = choice;

        
        this.isLoading = true;
        axiosIns.get("/getfiltredreports/" + JSON.stringify(choice) + "/" + this.year + "/" + this.mod + "/" + this.course)
          .then(({ data }) => {
            this.reports = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
        
      },

      nextPage(){
        this.isLOadingQuestions = true;
        axiosIns.get(this.nextp)
        .then(({ data }) => {
          this.reports = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },

      prevPage(){
        this.isLOadingQuestions = true;
        axiosIns.get(this.prevp)
        .then(({ data }) => {
          this.reports = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },

      refine(page){
        let choice = [];
        this.reportType.forEach(element => {
          choice = [...choice,element]
        });
        if(choice.length == 0){
          choice  =  ["La réponse de cette question est erronée","L'explication de cette question est inconvenable","La question ne correspond pas à ce cours","Il y a une erreur de grammaire","Aucune de ces réponses"];
        }
        this.isLOadingQuestions = true;
        axiosIns.get("/getfiltredreports/" + JSON.stringify(choice) + "/" + this.year + "/" + this.mod + "/" + this.course +"?page="+page)
        .then(({ data }) => {
          this.reports = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },    
    },

    computed: {
      isToken() {
        return this.$store.getters.get_token;
      },
     
    },
     
    setup() {
      const errorm = () => {
        ElMessage.error('Une erreur est survenue !')
      }
  
    return {
      errorm
    }
  },
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>